import {combineReducers} from 'redux'
import { createStore,applyMiddleware } from 'redux'
import { getAllCategoriesReducers } from './Reducers/CategoriesReducer'
import { getAllCategoriesProductsReducers } from './Reducers/CategoriesProductsReducer'
import {getSingleProductReducers} from './Reducers/SingleProductReducer'
import { getRelatedProductsReducers } from './Reducers/RelatedReducer'
import { getAllCaracteristicasReducers } from './Reducers/CaracteristicasReducer'
import { sendCorreosReducers } from './Reducers/CorreosReducer'
import thunk from 'redux-thunk'

import {composeWithDevTools} from 'redux-devtools-extension'


const finalReducer = combineReducers({
    getAllCategoriesReducers:getAllCategoriesReducers,
    getAllCategoriesProductsReducers:getAllCategoriesProductsReducers,
    getSingleProductReducers:getSingleProductReducers,
    getRelatedProductsReducers:getRelatedProductsReducers,
    getAllCaracteristicasReducers:getAllCaracteristicasReducers,
    sendCorreosReducers:sendCorreosReducers
})

const componseEnhancers = composeWithDevTools({})
const store = createStore(finalReducer,componseEnhancers(applyMiddleware(thunk)))

export default store