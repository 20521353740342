export const getAllCaracteristicasReducers=(state={caracteristicas:[]},action)=>{

    switch(action.type)
    {
        case 'GET_CARACTERISTICAS_REQUEST':return{
            loading:true,
            ...state
        }
        case 'GET_CARACTERISTICAS_SUCCESS':return{
            loading:false,
            caracteristicas:action.payload
        }
        case 'GET_CARACTERISTICAS_FAILED':return{
            error:action.payload,
            loading:false
        }
        default:return state
    }
}