import axios from "axios"
export const sendCorreos = (mail) => async (dispatch) => {

    dispatch({type:'SEND_CORREO_REQUEST'})

    try{
       const response = await axios.post(`https://mysqlbe.onrender.com/correos`,mail);
       console.log(response);
       dispatch({type:'SEND_CORREO_SUCCESS',payload : response.data}) 
    }catch(error){
        dispatch({type:'SEND_CORREO_FAILED',payload:error})
    }
    
}