import axios from "axios"
export const getSingleProduct = (productname) => async (dispatch) => {
  dispatch({ type: 'GET_SINGLEPRODUCT_REQUEST' });

  try {
    const response = await axios.get(`https://mysqlbe.onrender.com/Product/${productname}`);
    const first = response.data[0].pictures[0]
    const ProdID =response.data[0].Prod_ID
    console.log(response);

    dispatch({ type: 'GET_SINGLEPRODUCT_SUCCESS', payload: response.data,first,ProdID });
  } catch (error) {
    dispatch({ type: 'GET_SINGLEPRODUCT_FAILED', payload: error });
  }
};
