export default function About(){

    return(
        <>
            <section>

           
    
        <div class="content">
            <h1>Sobre Nosotros</h1>
            <span></span>
    
            <p style={{ textAlign: "justify" }}>Con años de experiencia en la industria, nos hemos convertido en un referente en el mercado, ofreciendo productos y servicios de calidad que se adaptan a las necesidades únicas de cada negocio. Trabajamos en estrecha colaboración con nuestros clientes para comprender sus requerimientos y ofrecer soluciones personalizadas que impulsen su crecimiento y éxito.  </p>
    
            <ul class="links">
                <li>Trabajo</li>
    
                <div class="vertical-line"></div>
    
                <li>Servicio</li>
    
                <div class="vertical-line"></div>
    
                <li>Contacto</li>
            </ul>
    
        </div>
    </section>
        </>
    )
}
