import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel, Button } from "react-bootstrap";
import { getAllCategories } from "../Actions/CategoriesActions";
import { useDispatch } from "react-redux";
import Sponsors from '../Comps/Sponsors'
import ReactPlayer from "react-player";
import Vid1 from "../vids/1.mov";
import ImageFroin from '../Pics/IMG21.jpg'
import Slide from "../Comps/Slide";
import Modal from "../Comps/modal";
import image1 from "../newdates/001.png"
import image2 from "../newdates/002.png"
import image3 from "../newdates/003.png"
import image4 from "../newdates/004.png"
import image5 from "../newdates/005.jpg"



const ProductosOferta=[
  {
    ProdName:"Kiosco SAT KT32LC",
    text:"Experimenta la innovación con la serie KT32: Pantallas táctiles capacitivas de 32 pulgadas, resolución 1920x1080 y vida útil de 50.000 horas. Potenciada por un Intel i5, 4GB RAM y 128GB SSD. Incluye impresora térmica, lector de códigos 2D, NFC y soporte para datafono.",
    image:"https://tboxsa.com/Images/Offer3.png",
    cat:"Kiosco",
    dir:"Kiosco Transacional Capacitivo SAT KT32LC I5"
  },
  {
    ProdName:"All in One Pulse Ultra",
    text:"El potente Pulse Ultra POS, con pantalla táctil, está equipado con 8 GB de RAM y transfiere datos al doble de velocidad que la mayoría de sistemas de punto de venta. Esto permite agilizar las transacciones, mejorar el servicio al cliente y brindar experiencias inigualables.",
    image:"https://tboxsa.com/Images/Offer1.png",
    cat:"All in One",
    dir:"All in One Pulse Ultra"
  },
  {
    ProdName:"Impresora Termica 22T",
    text:"Descubre la impresora térmica SAT 22T UE, diseñada para brindar un rendimiento excepcional en tu punto de venta. Con una velocidad de impresión de hasta 200mm/s, agiliza tus transacciones y mejora la experiencia de tus clientes.",
    image:"https://tboxsa.com/Images/Offer2.png",
    cat:"Impresoras",
    dir:"Impresora Termica SAT 22T UE"
  }
]

export default function Home() {
  //console.log(image1)

  



  const [index1, setIndex1] = useState(0);
  const dispatch = useDispatch();
  const listImagenes = [image1, image2, image3, image4 , image5]

 

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const handleSelect1 = (selectedIndex1) => {
    setIndex1(selectedIndex1);
  };

  return (
    <div>

      <Modal imagenes={listImagenes} ></Modal>
      
      

      
      <Carousel indicators={false} >
        <Carousel.Item>
          <div className="image-overlay">
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://tboxsa.com/Images/videos/3.mp4"
                width="100%"
                height="100%"
                controls={false}
                loop={true}
                playing={true}
              />
            </div>
          </div>
          <Carousel.Caption>
            <h3>¡Optimiza tus operaciones con nosotros!</h3>  
          </Carousel.Caption>
        </Carousel.Item>
        
        <Carousel.Item>
        <div className="image-overlay">
            <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={Vid1}
              width="100%"
              height="100%"
              controls={false}
              loop={true}
              playing={true}
            />
          </div>
        </div>
          <Carousel.Caption className="contenido">
            <h3>¡Descubre el poder de nuestros puntos de venta!</h3>
            
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="image-overlay">
            <img              
              src={ImageFroin}
              alt="Third slide"
              style={{width:'100%',height:"1072px"}}
              className="homeslidepic"
            />
          </div>
          <Carousel.Caption>
            <h3>¡Impulsa tu negocio con nuestros productos!</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      

      <h2 className="titulo">Categorías</h2>

      <Slide />

      <div className="offer"  >
        <div className="minicontainers">
          <Carousel
            activeIndex={index1}
            onSelect={handleSelect1}
            wrap="true"
            indicators={false}
          >

            {ProductosOferta.map((prods,index)=>(
            <Carousel.Item key={index}>
              <div className="row">
                <div className="col-2">
                  <img
                    src={prods.image}
                    className="offer-img"
                    alt="Prod Oferta"    
                    style={{height:'300px',padding:'0px'}}        
                  />
                </div>
                <div className="col-2">                  
                  <h1>{prods.ProdName}</h1>
                  <p style={{ textAlign: "justify" }}>
                    {prods.text}
                  </p>                  
                   <Button href={`/${prods.cat}/${prods.dir}`}>Ver detalles</Button>
                </div>
          
              </div>
            </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      
      <h2 className="titulo">Nuestros Partners</h2>
        <Sponsors/>


    

    </div>
  );
}
