import axios from "axios"
export const getAllCategoriesProducts = (categorieName) => async (dispatch) => {
  dispatch({ type: 'GET_PRODCATEGORIES_REQUEST' });

  try {
    const response = await axios.get(`https://mysqlbe.onrender.com/Prod/${categorieName}`);
    console.log(response);
    dispatch({ type: 'GET_PRODCATEGORIES_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_PRODCATEGORIES_FAILED', payload: error });
  }
};
