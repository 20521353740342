import Back from "../Pics/Nisgn.jpeg";
import Diagrama from "../Pics/Diagramaxd.png";
import Diagram1a from "../Pics/Nisgn1.png";
import AboutBackground from "../Pics/Nsign2.jpeg";
export default function Nsigntv(){
  return (
    <>
      <div className="home-banner-container">
        <div className="home-bannerImage-container" style={{backgroundColor:'#f2f2f2'}}>
          <img src={Back} alt="" />
        </div>
        <div className="home-text-section">
          
          <h1 className="primary-heading"  style={{margin:'auto'}}>
Lanza fácilmente tu contenido digital desde donde quiera que estés
          </h1>          
        </div>
        <div className="home-image-section" >
          <img src="https://tboxsa.com/Images/N1.png" alt="" style={{padding:'10px'}}/>
        </div>
      </div>
      
      <div className="about-section-container" style={{marginTop:'auto'}}>
        <div className="about-background-image-container">
          <img src={AboutBackground} alt="" />
        </div>
        <div className="about-section-image-container">
          <img src={Diagram1a} alt="" />
        </div>
        <div className="about-section-text-container">          
          <h1 className="primary-heading">Construye tu propio ecosistema</h1>
          <p className="primary-text">
La mejor combinación de contenidos y dispositivos digitales para impactar a tus audiencias de forma personalizada a lo largo de su Customer Journey para crear una experiencia memorable.
          </p>
        </div>
      </div>
      <div className="work-section-wrapper"  style={{marginTop:'auto'}}>
        <div className="work-section-top">

          <h1 className="primary-heading">Como funciona</h1>
          <p className="primary-text">
La plataforma de comunicación omnicanal más amigable y escalable que almacena, organiza, programa, y emite contenidos medibles en cualquier dispositivo digital sin dificultad de uso ni freno tecnológico.
          </p>
        </div>
        <div className="work-section-bottom" style={{width:'100%'}}>
   <img src={Diagrama} alt=""/>
        </div>
      </div>
    </>
  );
}
