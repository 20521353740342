import axios from "axios"
export const getRelatedProducts = (categoryname,productid) => async (dispatch) => {
  dispatch({ type: 'GET_RELATEDPRODS_REQUEST' });

  try {
    const response = await axios.get(`https://mysqlbe.onrender.com/products/${categoryname}/${productid}`);
    console.log(response);
    dispatch({ type: 'GET_RELATEDPRODS_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'GET_RELATEDPRODS_FAILED', payload: error });
  }
};
