import Back from "../Pics/Untitled (2).jpeg";
import S1 from '../Pics/1.png'
import S2 from '../Pics/2.png'
import S3 from '../Pics/3.png'
import AboutBackground from "../Pics/Untitled (3).jpeg";
export default function Pricer() {
  const workInfoData = [
    {
      image: S1,    
      text: "Conectamos tu base de datos con nuestro sistema para enviar datos a Pricer.",
    },
    {
      image: S2,      
      text: "Transferimos los datos al servidor de Pricer",
    },
    {
      image: S3,    
      text: "Los datos actualizados se muestran en la etiqueta electrónica y ya están listos para ser utilizados.",
    },
  ];
  return (
    <>
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={Back} alt="" />
        </div>
        <div className="home-text-section">
          
          <p className="primary-text"  style={{margin:'auto'}}>
            Mejora la comunicación con nuestras soluciones de automatización.
            Nuestras etiquetas electrónicas para estanterías (ESL) y tecnología
            de comunicación óptica exclusiva te permiten ahorrar tiempo y dinero
            en el sector Retail, brindando una experiencia de compra
            inigualable.
          </p>
        </div>
        <div className="home-image-section">
          <img src="https://tboxsa.com/Images/Tag2.png" alt=""/>
        </div>
      </div>
      
      <div className="about-section-container" style={{marginTop:'auto'}}>
        <div className="about-background-image-container">
          <img src={AboutBackground} alt="" />
        </div>
        <div className="about-section-image-container">
          <img src="https://tboxsa.com/Images/Tag1.png" alt="" />
        </div>
        <div className="about-section-text-container">          
          <h1 className="primary-heading">Automatización de precios</h1>
          <p className="primary-text">
Simplifique las operaciones de su tienda y el comercio omnicanal con etiquetas electrónicas de calidad empresarial.
          </p>
          <p className="primary-text">
Nuestras ESL están ideadas y diseñadas en Suecia por algunos de los mejores ingenieros del mundo.
          </p>
        </div>
      </div>
      <div className="work-section-wrapper"  style={{marginTop:'auto'}}>
        <div className="work-section-top">

          <h1 className="primary-heading">Como funciona</h1>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map((data) => (
            <div className="work-section-info" key={data.title}>
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
