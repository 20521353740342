export const getSingleProductReducers = (state = { singleProduct: [], first: null,ProdID : null }, action) => {
  switch (action.type) {
    case 'GET_SINGLEPRODUCT_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'GET_SINGLEPRODUCT_SUCCESS':
      return {
        ...state,
        loading: false,
        singleProduct: action.payload,
        first: action.first,
        ProdID: action.ProdID
      };
    case 'GET_SINGLEPRODUCT_FAILED':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
