import { useState, useEffect } from "react";



export default function Modal ({imagenes}){
    var [countImage, setCountImage] = useState(0);
    const [imagen, setimagen] = useState();

    useEffect(()=>{
        setimagen(imagenes[countImage]);
        //console.log(imagenes.length)
    },[countImage]);

    useEffect(()=>{

        setTimeout(()=>{
            
            let newCount = countImage +1;
            setCountImage(newCount);

            if(countImage === imagenes.length-1)
                setCountImage(0);
            else
                setCountImage(countImage+1);
           
            
        }, 7000);
       
    },[imagen]);


    
    


    const ocultarModal = () =>{
        document.getElementById("myModal").style = "display : none;"  
    }

    const nextImage = () =>{
        var newCount = countImage +1
        setCountImage(newCount);
        if(countImage === imagenes.length -1)
         setCountImage(0);
        else
            setCountImage(countImage+1);

    }

    const previusImage = () =>{
        var newCount = countImage -1
        setCountImage(newCount);
        if(countImage === 0)
            setCountImage(imagenes.length-1)
        else
            setCountImage(countImage-1);
        //setimagen(imagenes[countImage]);

        
    }


    const click = (e) =>{
        if(e.target.className === 'modals')
        ocultarModal();
       
    }

    return(
        
             <div id="myModal" className="modals" onClick={(e)=>click(e)} >
                <div className="modals-content">
                <div className="carousel">
                    <img className="slidex" src={imagen} alt="Imagen"/>
                    <button className="btn btn-danger" onClick={ocultarModal}>Cerrar</button>
                </div>
                <a className="prev" onClick={previusImage}>&#10094; </a>
                <a className="next" onClick={nextImage}>&#10095;</a>
                </div>
               
            </div>

    

    );


}