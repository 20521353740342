import axios from "axios"
export const getAllCategories=()=>async dispatch=>{

    dispatch({type:'GET_CATEGORIES_REQUEST'})

    try{
       const response = await axios.get('https://mysqlbe.onrender.com/categorias')
      // console.log(response);
       dispatch({type:'GET_CATEGORIES_SUCCESS',payload : response.data}) 
    }catch(error){
        dispatch({type:'GET_CATEGORIES_FAILED',payload:error})
    }
    
}