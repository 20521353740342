import { Button,Modal } from "react-bootstrap";
import React, { useState,useEffect } from "react";
import { sendCorreos } from "../Actions/CorreosActions";
import { useDispatch,useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import {
faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
export default function Contact() {
  const dispatch = useDispatch();
   const mailStatus = useSelector((state) => state.sendCorreosReducers);
  const [Name, SetName] = useState("");
  const [LastName, SetLastName] = useState("");
  const [from, Setfrom] = useState("");
  const [text, Settext] = useState("");
  const [Numero, SetNumero] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  function add() {
       if (!Name || !LastName || !from || !text || !Numero) {
      setError("Porfavor llene todos los campos");
      return;
    }
    setError("");
    const mail = {
      Name,
      LastName,
      from,
      text,
      Numero
    };
    dispatch(sendCorreos(mail));
  }

    useEffect(() => {
    if (mailStatus.success) {      
      setShowModal(true);
      SetName("");
      SetLastName("");
      Setfrom("");
      Settext("");
      SetNumero("");
    }
  }, [mailStatus.success]);

 const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="location">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d719.6968157040334!2d-87.22526533513366!3d14.04375348024602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f6f97961cb47719%3A0x9f3bacbfe0acf7cb!2sTECNOLOGY%20BOX%20S.A.!5e0!3m2!1ses!2shn!4v1683301322357!5m2!1ses!2shn"
          allowFullScreen=""
          loading="lazy"
          style={{ height: 400 }}
          referrerPolicy="no-referrer-when-downgrade"
          title="Direccion TBOXSA"
        ></iframe>
      </div>
      <h2 className="titulo">Contáctanos</h2>
      <div>
        <div class="row">
          <div class="contact-col">
            <div>
              <i class="fa">
                <FontAwesomeIcon icon={faClock} />
              </i>
              <span>
                <h5>Lunes a Jueves</h5>
                <p>8:30 am - 5:30 pm </p>
                <h5>Viernes</h5>
                <p>9:00 am - 5pm </p>
              </span>
            </div>
            <div>
              <i class="fa">
                <FontAwesomeIcon icon={faPhone} />
              </i>
              <span>
                <h5>+504 2262-3232</h5>
                <h5>+504 9862-6972</h5>
              </span>
            </div>
            <div>
              <i class="fa">
                <FontAwesomeIcon icon={faMapMarker} />
              </i>
              <span>
                <h5>
                  Altos de Toncontín, Complejo El Trébol, Contiguo a Bodegas DHL, Tegucigalpa, Honduras.
                </h5>
              </span>
            </div>
                        <div>
              <i class="fa">
                <FontAwesomeIcon icon={faWhatsapp} />
              </i>
              <span>
                <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=50498826972&text=Technology%20Box%20S.A.">¡Chatea con nosotros!</a>
              </span>
            </div>
          </div>
<div class="contact-col">
  <div className="name-lastname" style={{marginBottom:'1px'}}>
    <input
      type="text"
      placeholder="Ingrese su Nombre"
      required
      value={Name}
      onChange={(e) => {
        SetName(e.target.value);
      }}
    />
    <input
      type="text"
      placeholder="Ingrese su Apellido"
      required
      value={LastName}
      onChange={(e) => {
        SetLastName(e.target.value);
      }}
    />
  </div>
  <input
    type="text"
    placeholder="Ingrese su Correo"
    required
    value={from}
    onChange={(e) => {
      Setfrom(e.target.value);
    }}
  />
  <input
    type="text"
    placeholder="Ingrese su Numero de contacto"
    required
    value={Numero}
    onChange={(e) => {
      SetNumero(e.target.value);
    }}
  />
  <textarea
    rows="8"
    placeholder="Mensaje"
    required
    value={text}
    onChange={(e) => {
      Settext(e.target.value);
    }}
  ></textarea>
  <div style={{ color: "red" }}>{error}</div> 
<Button onClick={add} className="buttonxd" variant="success" disabled={mailStatus.loading}>
        {mailStatus.loading ? "Enviando..." : "Enviar"}
      </Button>
      {mailStatus.error && <p>Error enviando el mensaje</p>}
       <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Correo Enviado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Su correo se ha enviado correctamente, nos pondremos en contacto con usted pronto!</p>
        </Modal.Body>
      </Modal>
</div>

        </div>
      </div>
    </>
  );
}
