import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { getAllCategories } from "../Actions/CategoriesActions";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

export default function T3esdt() {
  const dispatch = useDispatch();
  const categoriesstate = useSelector(
    (state) => state.getAllCategoriesReducers
  );
  const { categories } = categoriesstate;
  const [scrollBackground, setScrollBackground] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const fixedTopRoutes = ["/","/Pricer","/Nsigntv"];

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollThreshold = 100;

      setScrollBackground(currentScrollPos > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navbarBackground = scrollBackground && !isMobile ? "navbar-bg-red" : "";

  const navbarSettings = {
    collapseOnSelect: true,
    expand: "lg",
    fixed: fixedTopRoutes.includes(location.pathname) && !isMobile ? "top" : "",
  };

  return (
    <header>
      <Navbar
        {...navbarSettings}
        expanded={expanded}
        className={
          fixedTopRoutes.includes(location.pathname)&& !isMobile ?
           navbarBackground
            : "navbar-bg-red"
        }
      >
        <Container>
          <Navbar.Brand>
            <a href="/">
              <img
                src="https://tboxsa.com/Images/tboxsa.png"
                className="brand"
                alt="Promo"
              />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/"> <b>Inicio</b></Nav.Link>
              <NavDropdown style={{fontWeight:'bold'}} title="Productos" id="collasible-nav-dropdown">
                {categories.map((categorias) => (
                  <NavDropdown.Item
                    key={categorias.Cat_Name}
                    href={`/Products/${categorias.Cat_Name}`}
                  >
                    {categorias.Cat_Name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
              <NavDropdown  style={{fontWeight:'bold'}} title="Soluciones" id="collasible-nav-dropdown">
                <NavDropdown.Item href={`/Pricer`}>Etiquetas Electronicas</NavDropdown.Item>
                <NavDropdown.Item href={`/Nsigntv`}>Digitalización Comercial</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/Contact"> <b>Contáctanos</b></Nav.Link>
              <Nav.Link href="/About"><b>Sobre Nosotros</b></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
