// reducers.js
export const sendCorreosReducers = (state = { mail: null, success: false }, action) => {
  switch (action.type) {
    case "SEND_CORREO_REQUEST":
      return {
        loading: true,
        ...state
      };
    case "SEND_CORREO_SUCCESS":
      return {
        loading: false,
        success: true, 
        mail: action.payload
      };
    case "SEND_CORREO_FAILED":
      return {
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
