export const getAllCategoriesProductsReducers=(state={categories:[]},action)=>{

    switch(action.type)
    {
        case 'GET_PRODCATEGORIES_REQUEST':return{
            loading:true,
            ...state
        }
        case 'GET_PRODCATEGORIES_SUCCESS':return{
            loading:false,
            categories:action.payload
        }
        case 'GET_PRODCATEGORIES_FAILED':return{
            error:action.payload,
            loading:false
        }
        default:return state
    }
}