import axios from "axios"
export const getAllCaracteristicas = (prodID) => async (dispatch) => {

    dispatch({type:'GET_CARACTERISTICAS_REQUEST'})

    try{
       const response = await axios.get(`https://mysqlbe.onrender.com/Caracteristicas/${prodID}`);
       console.log(response);
       dispatch({type:'GET_CARACTERISTICAS_SUCCESS',payload : response.data}) 
    }catch(error){
        dispatch({type:'GET_CARACTERISTICAS_FAILED',payload:error})
    }
    
}