import React, { useEffect } from "react";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../Actions/CategoriesActions";
import { useMediaQuery } from "react-responsive";

export default function Slide() {
  const dispatch = useDispatch();
  const categoriesstate = useSelector((state) => state.getAllCategoriesReducers);
  const { categories } = categoriesstate;

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    centerMode: true,
    centerPadding: "60px", 
    autoplay: true,
    autoplaySpeed: 2000, 
  };

  return (
    <div className="container">
      <Slider {...settings}>
        {categories.map((cats) => (
          <div key={cats.id}>
            <Card className="w-75 mx-auto">
              <a href={`/Products/${cats.Cat_Name}`}>
                <Card.Img variant="top" className="w-100" src={cats.Primary_Pic} />
              </a>
              <Card.Title className="text-center">{cats.Cat_Name}</Card.Title>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
}
