import React,{ useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProduct } from "../Actions/SingleProductsActions";
import { getRelatedProducts } from "../Actions/RelatedActions";
import { getAllCaracteristicas } from "../Actions/CaracteristicasActions";
import { Modal } from "react-bootstrap";

export default function SingleProd() {
  const { Categorie, Prod } = useParams();
  const dispatch = useDispatch();
  const singleProductState = useSelector(
    (state) => state.getSingleProductReducers
  );
  const relatedproductsState = useSelector(
    (state) => state.getRelatedProductsReducers
  );
  const caracteristicasState = useSelector(
    (state) => state.getAllCaracteristicasReducers
  );
  const [showModal, setShowModal] = useState(false);

  const { singleProduct, first, ProdID } = singleProductState;
  const { products } = relatedproductsState;
  const { caracteristicas } = caracteristicasState;

  useEffect(() => {
    dispatch(getSingleProduct(Prod));
  }, [dispatch, Prod]);

  console.log(caracteristicas);
  useEffect(() => {
    dispatch(getRelatedProducts(Categorie, Prod));
  }, [dispatch, Categorie, Prod]);

  useEffect(() => {
    dispatch(getAllCaracteristicas(Prod));
  }, [dispatch, ProdID, Prod]);

  const handleMiniFotoClick = (src) => {
    dispatch({
      type: "GET_SINGLEPRODUCT_SUCCESS",
      payload: singleProduct,
      first: src,
    });
  };

  console.log(handleMiniFotoClick);

  const handleVerMasClick = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="minicontainer producto-solo">
        <div className="row">
          <div className="col-2">
            <img className="d-block w-100" src={first} alt="Product" />
            <div className="img-mini-row">
              {singleProduct.length > 0 &&
                singleProduct.map((cats) =>
                  cats.pictures
                    .filter((picture) => picture && picture !== "")
                    .map((picture, index) => (
                      <div className="img-mini-col" key={index}>
                        <img
                          className="d-block w-100 minifoto"
                          src={picture}
                          alt={`Product ${index + 1}`}
                          onClick={() => handleMiniFotoClick(picture)}
                        />
                      </div>
                    ))
                )}
            </div>
          </div>

          {singleProduct.map((cats) => (
            <React.Fragment key={cats.id}>
              <div className="col-2">
                <h1>{Prod}</h1>

                <h3>Detalles del producto</h3>
                <p style={{ textAlign: "justify" }}>{cats.Description}</p>
                <a
                  className="button"
                  onClick={handleVerMasClick}
                  style={{ cursor: "pointer" }}
                >
                  Ver más
                </a>
                <a
                  href={cats.Ficha}
                  rel="noreferrer"
                  target="_blank"
                  className="button"
                >
                  Ficha Técnica
                </a>
                {cats.Drivers_Equipo && cats.Drivers_Equipo !== "" && (
                  <a
                    href="https://www.dropbox.com/scl/fi/xqypxkobyuhojxr1kr5dm/Driver-Windows-SAT-TT448-2.exe?rlkey=e49otiilsp1z87d2ohfq58q3o&dl=0"
                    rel="noreferrer"
                    target="_blank"
                    className="button"
                  >
                    Drivers del equipo
                  </a>
                )}
              </div>
              <Modal
                show={showModal}
                onHide={handleVerMasClick}
                size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Caracteristicas de {Prod}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {caracteristicas.map((stats, index) => (
                    <div key={index}>
                      {Object.entries(stats)
                        .filter(
                          ([key]) =>
                            ![
                              "Description",
                              "Prod_Name",
                              "Prod_ID",
                              "Pic1",
                              "Pic2",
                              "Pic3",
                              "Pic4",
                              "Primary_Pic",
                              "Cat_ID",
                              "Drivers_Equipo",
                              "Ficha",
                            ].includes(key)
                        )
                        .map(([key, value]) => (
                          <div key={key}>
                            <h5>{key}</h5>
                            <p>{value}</p>
                          </div>
                        ))}
                      <hr />
                    </div>
                  ))}
                </Modal.Body>
              </Modal>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="minicontainer">
        <div className="row row-2">
          <h2>Productos Relacionados</h2>
          <a href={`/Products/${Categorie}/`} className="texts">
            Ver más
          </a>
        </div>
      </div>

      <div className="minicontainer">
        <div className="row">
          {products.map((prod, index) => (
            <div className="col-4" key={index}>
              <a href={`/${Categorie}/${prod.Prod_Name}`}>
                <img
                  className="d-block w-100"
                  src={prod.Primary_Pic}
                  alt="First slide"
                />
              </a>
              <h4>{prod.Prod_Name}</h4>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
