export const getRelatedProductsReducers = (state = { products: [] }, action) => {
  switch (action.type) {
    case 'GET_RELATEDPRODS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'GET_RELATEDPRODS_SUCCESS':
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    case 'GET_RELATEDPRODS_FAILED':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
