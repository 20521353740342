import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF,faLinkedinIn,faInstagram,faWhatsapp} from '@fortawesome/free-brands-svg-icons'
export default function Footer(){
    return(
            <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>Technology Box S.A. </h6>
            <p className="text-justify"><i></i> Somos una empresa líder en soluciones de punto de venta. Nos dedicamos a proveer equipos y sistemas de última generación para ayudar a nuestros clientes a optimizar sus operaciones comerciales.</p>
          </div> -


          <div className="col-xs-6 col-md-3">
            <h6>Enlaces</h6>
            <ul className="footer-links">
              <li><a href="/About">Sobre Nosotros</a></li>
              <li><a href="/Contact">Contáctanos</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2023 Todos los derechos Reservados por  
         <a style={{paddingLeft:'10px'}} href="/">TBOXSA</a>.
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/tboxsahn/"><i className="fab "><FontAwesomeIcon icon={faFacebookF}/></i></a></li>
              <li><a className="twitter" target="_blank" rel="noreferrer" href="https://www.instagram.com/tboxsahn/"><i className="fab "><FontAwesomeIcon icon={faInstagram}/></i></a></li>
              <li><a className="dribbble" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/tboxsahn/"><i className="fab"><FontAwesomeIcon icon={faLinkedinIn}/></i></a></li>
              <li><a className="whats" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=50498826972&text=Technology%20Box%20S.A."><i className="fab"><FontAwesomeIcon icon={faWhatsapp}/></i></a></li>
            </ul>
          </div>
        </div>
      </div>
</footer>
    )
}