import React, { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";

export default function ImagesLoader({ src }) {
  const [imageLoad, setImageLoad] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoad(true);
    };
    img.src = src;
  }, [src]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          visibility: imageLoad ? "hidden" : "visible",
        }}
      >
        <Blurhash
          hash="LID3Q:Oj8ereB_oevoaPU5aMDQpG"
          width="100%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
      <img
        src={src}
        alt="Product"
        style={{ width: "100%", height: "100%", visibility: imageLoad ? "visible" : "hidden" }}
      />
    </div>
  );
}
