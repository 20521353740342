import {BrowserRouter,Routes,Route,useLocation} from "react-router-dom"
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import Home from '../src/Pages/Home'
import SingleProd from '../src/Pages/SingleProd'
import Contact from '../src/Pages/Contact'
import About from "./Pages/About";
import SingleCategorie from "./Pages/SingleCategorie";
import Pricer from "./Pages/Pricer";
import Nsigntv from "./Pages/Nsigntv"
import T3esdt from "./Comps/Navbar";
import Footer from "./Comps/Footer";
function App() {
  return (
    <>
    <BrowserRouter> 
      <AppContent/>
    </BrowserRouter>

    </>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <>
      <T3esdt className={location.pathname !== "/" ? 'navbar-bg-red' : ''} />
      <div className="continer">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Products/:Categorie" element={<SingleCategorie />} />
          <Route path="/:Categorie/:Prod" element={<SingleProd />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/Pricer" element={<Pricer />} />
          <Route path="/Nsigntv" element={<Nsigntv />} />
        </Routes>
      </div>
      <Footer/>
    </>
  );
}


export default App;
