import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getAllCategoriesProducts } from "../Actions/CategoriesProductsActions";
import { useDispatch, useSelector } from "react-redux";
import ImagesLoader from "../Comps/ImagesLoader";
export default function SingleCategorie() {
  const dispatch = useDispatch();
  const prodcatsstate = useSelector(
    (state) => state.getAllCategoriesProductsReducers
  );
  const { categories } = prodcatsstate;

  const { Categorie } = useParams();
      
  useEffect(() => {
    dispatch(getAllCategoriesProducts(Categorie));
  }, [dispatch, Categorie]);
  console.log(categories);
  return (
    <>
      <div className="container ">
        <div className="row">
          <h2 className="titulo">{Categorie}</h2>
          {categories.map((productos,index) => (
            <div className="col-4" style={{ paddingBottom:'3.6rem'}} key={index}>
              <a href={`/${Categorie}/${productos.Prod_Name}`}>
                <ImagesLoader src={productos.Primary_Pic} />
              </a>
              <h5>{productos.Prod_Name}</h5>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
